.compliment {
  width: 90%;
  min-height: 50vh;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5); /* Half transparent background */

  /* Center align content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 95%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}


.compliment-text {
  font-size: min(10vw, 75px); /* Adjusted font size with a minimum of 18px */
  max-width: 80%; /* Set maximum width for the text */
  margin-bottom: 10px;
  font-family: 'Great Vibes', 'Bad Script', 'Roboto';
  color: white;
  text-align: center;
}

.addressee-info {
  display: flex;
  align-items: center;
}

.addressee-details {
  flex-grow: 1;
}

.addressee-name {
  font-size: 16px;
  font-weight: bold;
}

.signature {
  font-style: italic;
  margin-top: 10px;
}


.compliment-info {
  display: flex;
  align-items: center;
}

.sender-info,
.addressee-info {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.sender-avatar,
.addressee-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}

.sender-name,
.addressee-name {
  font-size: 14px;
}


.premium-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.sender-info {
  display: flex;
  align-items: center;
}


.sender-name {
  font-size: 14px;
}

.premium-badge {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.compliment-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: url('path/to/fashionable/texture.jpg');  Add your texture image path */
  background-size: cover;
}


.arrow {
  font-size: 18px;
}

.floating-badge {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2a0236;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: black;
  margin: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #777;
  cursor: pointer;
}

.app-buttons {
  margin-top: 20px;
}

.app-store-button,
.google-play-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #777;
  cursor: pointer;
}