.delete-account-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .warning-text {
    color: red;
    font-weight: bold;
  }
  
  .checkbox-label {
    display: block;
    margin-top: 10px;
  }
  